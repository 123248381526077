define("ember-bootstrap/templates/components/bs-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "R061y3RX",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,0,[\"icon\"]]],null,{\"statements\":[[7,\"i\",true],[11,\"class\",[23,0,[\"icon\"]]],[8],[9],[0,\" \"]],\"parameters\":[]},null],[1,[23,0,[\"text\"]],false],[14,1,[[28,\"hash\",null,[[\"isFulfilled\",\"isPending\",\"isRejected\",\"isSettled\"],[[23,0,[\"isFulfilled\"]],[23,0,[\"isPending\"]],[23,0,[\"isRejected\"]],[23,0,[\"isSettled\"]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-button.hbs"
    }
  });

  _exports.default = _default;
});