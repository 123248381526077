define("@glimmer/reference", ["exports", "@glimmer/util"], function (_exports, _util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.VOLATILE_TAG = _exports.VOLATILE = _exports.ReferenceIterator = _exports.ReferenceCache = _exports.MonomorphicTagImpl = _exports.ListItem = _exports.IteratorSynchronizer = _exports.IterationArtifacts = _exports.INITIAL = _exports.ConstReference = _exports.CachedReference = _exports.CURRENT_TAG = _exports.CONSTANT_TAG = _exports.CONSTANT = _exports.COMPUTE = _exports.ALLOW_CYCLES = void 0;
  _exports.bump = bump;
  _exports.combine = combine;
  _exports.combineSlice = combineSlice;
  _exports.combineTagged = combineTagged;
  _exports.createTag = createTag;
  _exports.createUpdatableTag = createUpdatableTag;
  _exports.dirty = void 0;
  _exports.isConst = isConst;
  _exports.isConstTag = isConstTag;
  _exports.isModified = isModified;
  _exports.map = map;
  _exports.update = void 0;
  _exports.validate = validate;
  _exports.value = value;
  var symbol = typeof Symbol !== 'undefined' ? Symbol : key => `__${key}${Math.floor(Math.random() * Date.now())}__`;
  var CONSTANT = 0;
  _exports.CONSTANT = CONSTANT;
  var INITIAL = 1;
  _exports.INITIAL = INITIAL;
  var VOLATILE = 9007199254740991; // MAX_INT

  _exports.VOLATILE = VOLATILE;
  var $REVISION = INITIAL;

  function bump() {
    $REVISION++;
  } //////////


  var COMPUTE = symbol('TAG_COMPUTE'); //////////

  /**
   * `value` receives a tag and returns an opaque Revision based on that tag. This
   * snapshot can then later be passed to `validate` with the same tag to
   * determine if the tag has changed at all since the time that `value` was
   * called.
   *
   * The current implementation returns the global revision count directly for
   * performance reasons. This is an implementation detail, and should not be
   * relied on directly by users of these APIs. Instead, Revisions should be
   * treated as if they are opaque/unknown, and should only be interacted with via
   * the `value`/`validate` API.
   *
   * @param tag
   */

  _exports.COMPUTE = COMPUTE;

  function value(_tag) {
    return $REVISION;
  }
  /**
   * `validate` receives a tag and a snapshot from a previous call to `value` with
   * the same tag, and determines if the tag is still valid compared to the
   * snapshot. If the tag's state has changed at all since then, `validate` will
   * return false, otherwise it will return true. This is used to determine if a
   * calculation related to the tags should be rerun.
   *
   * @param tag
   * @param snapshot
   */


  function validate(tag, snapshot) {
    return snapshot >= tag[COMPUTE]();
  }

  var TYPE = symbol('TAG_TYPE');
  var ALLOW_CYCLES;
  _exports.ALLOW_CYCLES = ALLOW_CYCLES;

  class MonomorphicTagImpl {
    constructor(type) {
      this.revision = INITIAL;
      this.lastChecked = INITIAL;
      this.lastValue = INITIAL;
      this.isUpdating = false;
      this.subtags = null;
      this.subtag = null;
      this.subtagBufferCache = null;
      this[TYPE] = type;
    }

    [COMPUTE]() {
      var {
        lastChecked
      } = this;

      if (lastChecked !== $REVISION) {
        this.isUpdating = true;
        this.lastChecked = $REVISION;

        try {
          var {
            subtags,
            subtag,
            subtagBufferCache,
            lastValue,
            revision
          } = this;

          if (subtag !== null) {
            var subtagValue = subtag[COMPUTE]();

            if (subtagValue === subtagBufferCache) {
              revision = Math.max(revision, lastValue);
            } else {
              // Clear the temporary buffer cache
              this.subtagBufferCache = null;
              revision = Math.max(revision, subtagValue);
            }
          }

          if (subtags !== null) {
            for (var i = 0; i < subtags.length; i++) {
              var _value = subtags[i][COMPUTE]();

              revision = Math.max(_value, revision);
            }
          }

          this.lastValue = revision;
        } finally {
          this.isUpdating = false;
        }
      }

      if (this.isUpdating === true) {
        this.lastChecked = ++$REVISION;
      }

      return this.lastValue;
    }

    static update(_tag, _subtag) {
      // TODO: TS 3.7 should allow us to do this via assertion
      var tag = _tag;
      var subtag = _subtag;

      if (subtag === CONSTANT_TAG) {
        tag.subtag = null;
      } else {
        // There are two different possibilities when updating a subtag:
        //
        // 1. subtag[COMPUTE]() <= tag[COMPUTE]();
        // 2. subtag[COMPUTE]() > tag[COMPUTE]();
        //
        // The first possibility is completely fine within our caching model, but
        // the second possibility presents a problem. If the parent tag has
        // already been read, then it's value is cached and will not update to
        // reflect the subtag's greater value. Next time the cache is busted, the
        // subtag's value _will_ be read, and it's value will be _greater_ than
        // the saved snapshot of the parent, causing the resulting calculation to
        // be rerun erroneously.
        //
        // In order to prevent this, when we first update to a new subtag we store
        // its computed value, and then check against that computed value on
        // subsequent updates. If its value hasn't changed, then we return the
        // parent's previous value. Once the subtag changes for the first time,
        // we clear the cache and everything is finally in sync with the parent.
        tag.subtagBufferCache = subtag[COMPUTE]();
        tag.subtag = subtag;
      }
    }

    static dirty(tag) {
      tag.revision = ++$REVISION;
    }

  }

  _exports.MonomorphicTagImpl = MonomorphicTagImpl;
  var dirty = MonomorphicTagImpl.dirty;
  _exports.dirty = dirty;
  var update = MonomorphicTagImpl.update; //////////

  _exports.update = update;

  function createTag() {
    return new MonomorphicTagImpl(0
    /* Dirtyable */
    );
  }

  function createUpdatableTag() {
    return new MonomorphicTagImpl(1
    /* Updatable */
    );
  } //////////


  var CONSTANT_TAG = new MonomorphicTagImpl(3
  /* Constant */
  );
  _exports.CONSTANT_TAG = CONSTANT_TAG;

  function isConst(_ref) {
    var {
      tag
    } = _ref;
    return tag === CONSTANT_TAG;
  }

  function isConstTag(tag) {
    return tag === CONSTANT_TAG;
  } //////////


  class VolatileTag {
    [COMPUTE]() {
      return VOLATILE;
    }

  }

  var VOLATILE_TAG = new VolatileTag(); //////////

  _exports.VOLATILE_TAG = VOLATILE_TAG;

  class CurrentTag {
    [COMPUTE]() {
      return $REVISION;
    }

  }

  var CURRENT_TAG = new CurrentTag(); //////////

  _exports.CURRENT_TAG = CURRENT_TAG;

  function combineTagged(tagged) {
    var optimized = [];

    for (var i = 0, l = tagged.length; i < l; i++) {
      var tag = tagged[i].tag;
      if (tag === CONSTANT_TAG) continue;
      optimized.push(tag);
    }

    return _combine(optimized);
  }

  function combineSlice(slice) {
    var optimized = [];
    var node = slice.head();

    while (node !== null) {
      var tag = node.tag;
      if (tag !== CONSTANT_TAG) optimized.push(tag);
      node = slice.nextNode(node);
    }

    return _combine(optimized);
  }

  function combine(tags) {
    var optimized = [];

    for (var i = 0, l = tags.length; i < l; i++) {
      var tag = tags[i];
      if (tag === CONSTANT_TAG) continue;
      optimized.push(tag);
    }

    return _combine(optimized);
  }

  function _combine(tags) {
    switch (tags.length) {
      case 0:
        return CONSTANT_TAG;

      case 1:
        return tags[0];

      default:
        var tag = new MonomorphicTagImpl(2
        /* Combinator */
        );
        tag.subtags = tags;
        return tag;
    }
  }

  class CachedReference {
    constructor() {
      this.lastRevision = null;
      this.lastValue = null;
    }

    value() {
      var {
        tag,
        lastRevision,
        lastValue
      } = this;

      if (lastRevision === null || !validate(tag, lastRevision)) {
        lastValue = this.lastValue = this.compute();
        this.lastRevision = value(tag);
      }

      return lastValue;
    }

    invalidate() {
      this.lastRevision = null;
    }

  }

  _exports.CachedReference = CachedReference;

  class MapperReference extends CachedReference {
    constructor(reference, mapper) {
      super();
      this.tag = reference.tag;
      this.reference = reference;
      this.mapper = mapper;
    }

    compute() {
      var {
        reference,
        mapper
      } = this;
      return mapper(reference.value());
    }

  }

  function map(reference, mapper) {
    return new MapperReference(reference, mapper);
  } //////////


  class ReferenceCache {
    constructor(reference) {
      this.lastValue = null;
      this.lastRevision = null;
      this.initialized = false;
      this.tag = reference.tag;
      this.reference = reference;
    }

    peek() {
      if (!this.initialized) {
        return this.initialize();
      }

      return this.lastValue;
    }

    revalidate() {
      if (!this.initialized) {
        return this.initialize();
      }

      var {
        reference,
        lastRevision
      } = this;
      var tag = reference.tag;
      if (validate(tag, lastRevision)) return NOT_MODIFIED;
      this.lastRevision = value(tag);
      var {
        lastValue
      } = this;
      var currentValue = reference.value();
      if (currentValue === lastValue) return NOT_MODIFIED;
      this.lastValue = currentValue;
      return currentValue;
    }

    initialize() {
      var {
        reference
      } = this;
      var currentValue = this.lastValue = reference.value();
      this.lastRevision = value(reference.tag);
      this.initialized = true;
      return currentValue;
    }

  }

  _exports.ReferenceCache = ReferenceCache;
  var NOT_MODIFIED = 'adb3b78e-3d22-4e4b-877a-6317c2c5c145';

  function isModified(value$$1) {
    return value$$1 !== NOT_MODIFIED;
  }

  class ConstReference {
    constructor(inner) {
      this.inner = inner;
      this.tag = CONSTANT_TAG;
    }

    value() {
      return this.inner;
    }

  }

  _exports.ConstReference = ConstReference;

  class ListItem extends _util.ListNode {
    constructor(iterable, result) {
      super(iterable.valueReferenceFor(result));
      this.retained = false;
      this.seen = false;
      this.key = result.key;
      this.iterable = iterable;
      this.memo = iterable.memoReferenceFor(result);
    }

    update(item) {
      this.retained = true;
      this.iterable.updateValueReference(this.value, item);
      this.iterable.updateMemoReference(this.memo, item);
    }

    shouldRemove() {
      return !this.retained;
    }

    reset() {
      this.retained = false;
      this.seen = false;
    }

  }

  _exports.ListItem = ListItem;

  class IterationArtifacts {
    constructor(iterable) {
      this.iterator = null;
      this.map = (0, _util.dict)();
      this.list = new _util.LinkedList();
      this.tag = iterable.tag;
      this.iterable = iterable;
    }

    isEmpty() {
      var iterator = this.iterator = this.iterable.iterate();
      return iterator.isEmpty();
    }

    iterate() {
      var iterator;

      if (this.iterator === null) {
        iterator = this.iterable.iterate();
      } else {
        iterator = this.iterator;
      }

      this.iterator = null;
      return iterator;
    }

    has(key) {
      return !!this.map[key];
    }

    get(key) {
      return this.map[key];
    }

    wasSeen(key) {
      var node = this.map[key];
      return node !== undefined && node.seen;
    }

    append(item) {
      var {
        map,
        list,
        iterable
      } = this;
      var node = map[item.key] = new ListItem(iterable, item);
      list.append(node);
      return node;
    }

    insertBefore(item, reference) {
      var {
        map,
        list,
        iterable
      } = this;
      var node = map[item.key] = new ListItem(iterable, item);
      node.retained = true;
      list.insertBefore(node, reference);
      return node;
    }

    move(item, reference) {
      var {
        list
      } = this;
      item.retained = true;
      list.remove(item);
      list.insertBefore(item, reference);
    }

    remove(item) {
      var {
        list
      } = this;
      list.remove(item);
      delete this.map[item.key];
    }

    nextNode(item) {
      return this.list.nextNode(item);
    }

    head() {
      return this.list.head();
    }

  }

  _exports.IterationArtifacts = IterationArtifacts;

  class ReferenceIterator {
    // if anyone needs to construct this object with something other than
    // an iterable, let @wycats know.
    constructor(iterable) {
      this.iterator = null;
      var artifacts = new IterationArtifacts(iterable);
      this.artifacts = artifacts;
    }

    next() {
      var {
        artifacts
      } = this;
      var iterator = this.iterator = this.iterator || artifacts.iterate();
      var item = iterator.next();
      if (item === null) return null;
      return artifacts.append(item);
    }

  }

  _exports.ReferenceIterator = ReferenceIterator;
  var Phase;

  (function (Phase) {
    Phase[Phase["Append"] = 0] = "Append";
    Phase[Phase["Prune"] = 1] = "Prune";
    Phase[Phase["Done"] = 2] = "Done";
  })(Phase || (Phase = {}));

  class IteratorSynchronizer {
    constructor(_ref2) {
      var {
        target,
        artifacts
      } = _ref2;
      this.target = target;
      this.artifacts = artifacts;
      this.iterator = artifacts.iterate();
      this.current = artifacts.head();
    }

    sync() {
      var phase = Phase.Append;

      while (true) {
        switch (phase) {
          case Phase.Append:
            phase = this.nextAppend();
            break;

          case Phase.Prune:
            phase = this.nextPrune();
            break;

          case Phase.Done:
            this.nextDone();
            return;
        }
      }
    }

    advanceToKey(key) {
      var {
        current,
        artifacts
      } = this;
      var seek = current;

      while (seek !== null && seek.key !== key) {
        seek.seen = true;
        seek = artifacts.nextNode(seek);
      }

      if (seek !== null) {
        this.current = artifacts.nextNode(seek);
      }
    }

    nextAppend() {
      var {
        iterator,
        current,
        artifacts
      } = this;
      var item = iterator.next();

      if (item === null) {
        return this.startPrune();
      }

      var {
        key
      } = item;

      if (current !== null && current.key === key) {
        this.nextRetain(item);
      } else if (artifacts.has(key)) {
        this.nextMove(item);
      } else {
        this.nextInsert(item);
      }

      return Phase.Append;
    }

    nextRetain(item) {
      var {
        artifacts,
        current
      } = this;
      current = current;
      current.update(item);
      this.current = artifacts.nextNode(current);
      this.target.retain(item.key, current.value, current.memo);
    }

    nextMove(item) {
      var {
        current,
        artifacts,
        target
      } = this;
      var {
        key
      } = item;
      var found = artifacts.get(item.key);
      found.update(item);

      if (artifacts.wasSeen(item.key)) {
        artifacts.move(found, current);
        target.move(found.key, found.value, found.memo, current ? current.key : null);
      } else {
        this.advanceToKey(key);
      }
    }

    nextInsert(item) {
      var {
        artifacts,
        target,
        current
      } = this;
      var node = artifacts.insertBefore(item, current);
      target.insert(node.key, node.value, node.memo, current ? current.key : null);
    }

    startPrune() {
      this.current = this.artifacts.head();
      return Phase.Prune;
    }

    nextPrune() {
      var {
        artifacts,
        target,
        current
      } = this;

      if (current === null) {
        return Phase.Done;
      }

      var node = current;
      this.current = artifacts.nextNode(node);

      if (node.shouldRemove()) {
        artifacts.remove(node);
        target.delete(node.key);
      } else {
        node.reset();
      }

      return Phase.Prune;
    }

    nextDone() {
      this.target.done();
    }

  }

  _exports.IteratorSynchronizer = IteratorSynchronizer;
});