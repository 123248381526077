define("ember-awesome-macros/array/sort-by", ["exports", "ember-awesome-macros/array/sort"], function (_exports, _sort) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = sortBy;

  function sortBy(array, key) {
    if (typeof key !== 'string') {
      throw new TypeError('key must be a string');
    }

    return (0, _sort.default)(array, [key]);
  }
});