define("ember-leaflet/services/ember-leaflet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    init() {
      this._super(...arguments);

      this.set('components', []);
    },

    registerComponent(name) {
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      let components = this.get('components');
      let as = options.as || name;
      (false && !(components.find(c => c.name === name || c.as === as) === undefined) && Ember.assert(`Tried to register component \`${name}\` as \`${as}\`, but it was already registered. Try to register a different component or register it under a different name.`, components.find(c => c.name === name || c.as === as) === undefined));
      components.push({
        name,
        as
      });
    }

  });

  _exports.default = _default;
});