define("ember-moment/helpers/moment-from-now", ["exports", "ember-moment/utils/helper-compute", "ember-moment/helpers/-base"], function (_exports, _helperCompute, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    compute: (0, _helperCompute.default)(function (params, _ref) {
      let {
        hideAffix,
        locale,
        timeZone
      } = _ref;

      this._super(...arguments);

      const moment = this.moment;
      return this.morphMoment(moment.moment(...params), {
        locale,
        timeZone
      }).fromNow(hideAffix);
    })
  });

  _exports.default = _default;
});