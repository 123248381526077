define("ember-bootstrap/templates/components/bs-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wv086QBW",
    "block": "{\"symbols\":[\"@inNav\",\"&default\"],\"statements\":[[14,2,[[28,\"hash\",null,[[\"button\",\"toggle\",\"menu\",\"toggleDropdown\",\"openDropdown\",\"closeDropdown\",\"isOpen\"],[[28,\"component\",[[23,0,[\"buttonComponent\"]]],[[\"dropdown\",\"isOpen\",\"onClick\",\"onKeyDown\"],[[23,0,[]],[23,0,[\"isOpen\"]],[23,0,[\"toggleDropdown\"]],[23,0,[\"handleKeyEvent\"]]]]],[28,\"component\",[[23,0,[\"toggleComponent\"]]],[[\"dropdown\",\"isOpen\",\"inNav\",\"onClick\",\"onKeyDown\"],[[23,0,[]],[23,0,[\"isOpen\"]],[24,[\"inNav\"]],[23,0,[\"toggleDropdown\"]],[23,0,[\"handleKeyEvent\"]]]]],[28,\"component\",[[23,0,[\"menuComponent\"]]],[[\"isOpen\",\"direction\",\"inNav\",\"toggleElement\",\"dropdownElementId\"],[[23,0,[\"isOpen\"]],[23,0,[\"direction\"]],[23,1,[]],[23,0,[\"toggleElement\"]],[23,0,[\"elementId\"]]]]],[23,0,[\"toggleDropdown\"]],[23,0,[\"openDropdown\"]],[23,0,[\"closeDropdown\"]],[23,0,[\"isOpen\"]]]]]]],[0,\"\\n\"],[4,\"if\",[[23,0,[\"isOpen\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"on-document\",[\"keydown\",[23,0,[\"handleKeyEvent\"]]],null],false],[0,\"\\n  \"],[1,[28,\"on-document\",[\"click\",[23,0,[\"closeHandler\"]]],[[\"capture\"],[true]]],false],[0,\"\\n  \"],[1,[28,\"on-document\",[\"keyup\",[23,0,[\"closeHandler\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-dropdown.hbs"
    }
  });

  _exports.default = _default;
});