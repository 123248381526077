define("ember-leaflet/initializers/leaflet-assets", ["exports", "ember-get-config"], function (_exports, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  /* global L */
  function
    /* container, application */
  initialize() {
    if (typeof FastBoot === 'undefined' && typeof L !== 'undefined') {
      let prefix = '';

      if (!Ember.isNone(_emberGetConfig.default.rootURL)) {
        prefix = _emberGetConfig.default.rootURL;
      } else if (!Ember.isNone(_emberGetConfig.default.baseURL)) {
        prefix = _emberGetConfig.default.baseURL;
      }

      L.Icon.Default.imagePath = `${prefix}assets/images/`;
    }
  }

  var _default = {
    name: 'leaflet-assets',
    initialize
  };
  _exports.default = _default;
});