define("ember-bootstrap/templates/components/bs-modal/header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZevCTu5s",
    "block": "{\"symbols\":[\"@title\",\"&default\",\"@onClose\"],\"statements\":[[4,\"if\",[[26,2]],null,{\"statements\":[[0,\"  \"],[14,2,[[28,\"hash\",null,[[\"title\",\"close\"],[[28,\"component\",[[23,0,[\"titleComponent\"]]],null],[28,\"component\",[[23,0,[\"closeComponent\"]]],[[\"onClick\"],[[23,3,[]]]]]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,0,[\"closeButton\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"component\",[[23,0,[\"closeComponent\"]]],[[\"onClick\"],[[23,3,[]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[25,2]],null,{\"statements\":[[0,\"    \"],[14,2],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[4,\"component\",[[23,0,[\"titleComponent\"]]],null,{\"statements\":[[1,[23,1,[]],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-modal/header.hbs"
    }
  });

  _exports.default = _default;
});