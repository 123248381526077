define("ember-bootstrap/templates/components/bs-dropdown/menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XE1ZCYyo",
    "block": "{\"symbols\":[\"@toggleElement\",\"@dropdownElementId\",\"@class\",\"&attrs\",\"&default\"],\"statements\":[[4,\"if\",[[23,0,[\"_isOpen\"]]],null,{\"statements\":[[0,\"  \"],[5,\"ember-popper\",[[12,\"class\",\"ember-bootstrap-dropdown-bs3-popper\"]],[[\"@placement\",\"@popperTarget\",\"@renderInPlace\",\"@popperContainer\",\"@modifiers\",\"@onCreate\"],[[23,0,[\"popperPlacement\"]],[23,1,[]],[23,0,[\"_renderInPlace\"]],[23,0,[\"destinationElement\"]],[23,0,[\"popperModifiers\"]],[23,0,[\"setFocus\"]]]],{\"statements\":[[0,\"\\n    \"],[7,\"ul\",false],[12,\"id\",[29,[[23,2,[]],\"__menu\"]]],[12,\"class\",[29,[\"dropdown-menu \",[23,0,[\"alignClass\"]],\" \",[28,\"if\",[[23,0,[\"isOpen\"]],\"show\"],null],\" \",[23,3,[]]]]],[12,\"role\",[23,0,[\"ariaRole\"]]],[12,\"tabindex\",\"-1\"],[13,4],[8],[0,\"\\n      \"],[14,5,[[28,\"hash\",null,[[\"item\",\"link-to\",\"linkTo\",\"divider\"],[[28,\"component\",[[23,0,[\"itemComponent\"]]],null],[28,\"component\",[[23,0,[\"linkToComponent\"]]],null],[28,\"component\",[[23,0,[\"linkToComponent\"]]],null],[28,\"component\",[[23,0,[\"dividerComponent\"]]],null]]]]]],[0,\"\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-dropdown/menu.hbs"
    }
  });

  _exports.default = _default;
});