define("ember-leaflet/components/div-overlay-layer", ["exports", "ember-leaflet/components/base-layer", "ember-leaflet/templates/div-overlay", "ember-composability-tools"], function (_exports, _baseLayer, _divOverlay, _emberComposabilityTools) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseLayer.default.extend(_emberComposabilityTools.RenderBlockMixin, {
    layout: _divOverlay.default,
    leafletOptions: Object.freeze(['offset', 'className', 'pane']),
    fastboot: Ember.computed(function () {
      let owner = Ember.getOwner(this);
      return owner.lookup('service:fastboot');
    }),
    isFastBoot: Ember.computed.and('fastboot', 'fastboot.isFastBoot')
  });

  _exports.default = _default;
});