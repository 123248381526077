define("ember-leaflet/components/tile-layer", ["exports", "ember-leaflet/components/base-layer"], function (_exports, _baseLayer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseLayer.default.extend({
    leafletRequiredOptions: Object.freeze(['url']),
    leafletOptions: Object.freeze(['minZoom', 'maxZoom', 'maxNativeZoom', 'tileSize', 'subdomains', 'errorTileUrl', 'attribution', 'tms', 'continuousWorld', 'noWrap', 'zoomOffset', 'zoomReverse', 'opacity', 'zIndex', 'unloadInvisibleTiles', 'updateWhenIdle', 'detectRetina', 'reuseTiles', 'bounds', 'className', 'crossOrigin']),
    leafletEvents: Object.freeze(['loading', 'load', 'tileloadstart', 'tileload', 'tileunload']),
    leafletProperties: Object.freeze(['url:setUrl:noRedraw', 'zIndex', 'opacity']),

    createLayer() {
      return this.L.tileLayer(...this.get('requiredOptions'), this.get('options'));
    }

  });

  _exports.default = _default;
});